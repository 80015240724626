<template>
  <div class="about">
    <div class="banner">
      <!-- <img :src="`/api/ffs/file/${startProductInfo.starImage?.id}`" class="bannerImg" />
      <a target="_blank" :href="`/product/detailImgAndText?productId=${startProductInfo.productId}`">
        <el-link
        :underline="false">
          查看详情
        </el-link>
      </a> -->
      <div class="swiper-container" id="start-products-swipper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in startProductInfo" :key="index">
            <div class="star-product-box">
              <img :src="`/api/ffs/file/` + item.starImage.id" class="bannerImg" />
              <div class="slogan-container">
                <div class="slogan-text-panel">
                  <a v-if="item.product.imageTemplate" target="_blank"
                    :href="`/product/detailImg?productId=` + item.productId">
                    <el-link :underline="false">
                      查看详情
                    </el-link>
                  </a>
                  <a v-if="!item.product.imageTemplate" target="_blank"
                    :href="`/product/detailImgAndText?productId=` + item.productId">
                    <el-link :underline="false">
                      查看详情
                    </el-link>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination"></div>
      </div>
    </div>
    <div class="products-container">
      <!-- 分类 -->
      <div class="p-classify fs-flex fs-flex-between">
        <div class="p-classify-action fs-flex fs-flex-align-center" @click="currentSwiper.slidePrev()">
          <el-icon>
            <ArrowLeft />
          </el-icon>
        </div>
        <div class="swiper-container" id="product-types-swipper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" style="width: 137px;margin-right: 0px;" v-for="(type, index) in productTypeList"
              :key="index">
              <div class="mask-container">
                <div class="product-type-box"
                  :class="productType && productType.path == type.path ? 'selected-item' : undefined"
                  @click="selectProductType(type)">
                  <svg-image :src="'/api/ffs/file/' + type.image.id" :label="type.name" :width="60" :height="60"
                    :color="productType && productType.path == type.path ? 'rgba(0, 160, 233, 1)' : 'rgba(88, 88, 88, 1)'"
                    hover-color="rgba(0, 160, 233, 1)"></svg-image>
                </div>
              </div>
              <el-divider v-if="index > 0" class="product-type-divider" direction="vertical" />
            </div>
          </div>
        </div>
        <div class="p-classify-action fs-flex fs-flex-align-center" @click="currentSwiper.slideNext()">
          <el-icon>
            <ArrowRight />
          </el-icon>
        </div>
      </div>

      <!-- 产品一级分类版本下缘分隔条 -->
      <el-divider class="product-type-bar-divider" direction="horizontal" />

      <div class="products-pannel">
        <div class="condition-menu-docker">
          <div class="condition-menu">
            <div class="condition-selector-bar">
              <div class="condition-selector-label">
                <el-link :underline="false" @click="switchMenuExpanding">
                  品类
                  <template v-if="conditionMenuExpanded == '1'">
                    <el-icon class="menu-arrow">
                      <ArrowUp />
                    </el-icon>
                  </template>
                  <template v-else>
                    <el-icon class="menu-arrow">
                      <ArrowDown />
                    </el-icon>
                  </template>
                </el-link>
                <template v-if="productType || selectedSubType">
                  <el-tag closable type="info" @close="clearSelectedType()">
                    {{ productType ? productType.name : "" }} {{ selectedSubType ? " / " + selectedSubType.name : "" }}
                  </el-tag>
                </template>

              </div>
              <div class="condition-selector-label">
                <el-link :underline="false" @click="switchMenuExpanding">
                  品牌
                  <template v-if="conditionMenuExpanded == '1'">
                    <el-icon class="menu-arrow">
                      <ArrowUp />
                    </el-icon>
                  </template>
                  <template v-else>
                    <el-icon class="menu-arrow">
                      <ArrowDown />
                    </el-icon>
                  </template>
                </el-link>
                <template v-if="selectedBrand">
                  <el-tag closable type="info" @close="selectBrand(undefined)">
                    {{ selectedBrand.name }} &nbsp; {{ selectedBrand.englishName }}
                  </el-tag>
                </template>
              </div>
            </div>

            <div class="condition-name">
              <el-input v-model="productName" class="condition-name-input" placeholder="产品名称"
                @keyup.enter="searchProducts" @click="searchProducts">

                <template #suffix>
                  <el-icon class="el-input__icon" @click="searchProducts">
                    <search />
                  </el-icon>
                </template>
              </el-input>
            </div>
          </div>
        </div>
        <div class="condition-bar">
          <div class="condition-menu-panel" :class="conditionMenuExpanded ? 'condition-menu-expended' : undefined">
            <el-collapse v-model="conditionMenuExpanded" class="condition-menu-collapse" accordion>

              <el-collapse-item name="1">
                <template #title>
                  <div class="condition-menu-placeholder">
                  </div>
                </template>
                <div class="condition-options-panel">
                  <div style="display: flex;width: 100%;">
                    <div class="condition-options-types">
                      <!-- <template v-for="(subtype, index) in productSubTypeList" :key="index">
                        <el-button class="condition-option"
                          :class="selectedSubType && subtype.path == selectedSubType.path ? 'selected-item' : undefined"
                          link @click="selectProductSubType(subtype)"><span class="condition-option-text">{{
                              subtype.name
                          }}</span></el-button>
                      </template> -->
                      <el-cascader-panel ref="cascaderPanel" class="types-pannel" :model-value="selectedTypes" :props="{expandTrigger:'hover'}" :options="productTypeOptions" @change="selectProductSubType" />
                    </div>
                    <div class="condition-options-bands">
                      <template v-for="(brand, index) in brandList" :key="index">
                        <el-button class="condition-option"
                          :class="selectedBrand && brand.id == selectedBrand.id ? 'selected-item' : undefined" link
                          @click="selectBrand(brand)"><span class="condition-option-text">{{ brand.name }} &nbsp;
                            {{ brand.englishName }}</span></el-button>
                      </template>
                    </div>
                  </div>
                  <div class="condition-name"></div>
                </div>
              </el-collapse-item>

            </el-collapse>
          </div>
        </div>

        <div style="box-sizing: border-box; padding: 15px 0">
          <div class="newsBox">

            <el-row :gutter="24">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-for="(item, index) in productList" :key="index"
                style="margin-bottom: 24px">
                <div style="cursor: pointer" @click="handleProductClick(item)">
                  <div class="productImage">
                    <img style="width: 100%;border-radius: 10px;display: block;"
                      :src="`/api/ffs/file/${item.coverImage.id}`" alt="" />
                    <div class="imgMask">
                      <h3>
                        <el-icon>
                          <Plus />
                        </el-icon>
                      </h3>

                    </div>
                  </div>
                  <div class="detailBottom">
                    <div class="textCh">{{ item.chineseName }}</div>
                    <div class="textEn">{{ item.englishName }}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { inject } from "vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";

//import { useRoute } from "vue-router";

export default {
  name: "product-center",

  setup() {
    // 标识当前页面，为导航菜单高亮当前菜单项；
    const pageIndicator = inject("pageIndicator");
    pageIndicator.currentPageName = "product";
    pageIndicator.lightMode = false; // 导航栏背景适应浅色内容模式；
    pageIndicator.headerTransparent = false; // 导航栏背景全透明；
    pageIndicator.headerGradient = false; // 导航栏背景渐变；
  },

  data() {
    return {
      conditionMenuExpanded: undefined,// 条件菜单是否已展开；

      productType: undefined, // 选中的一级分类；
      selectedSubType: undefined, //选中的二级分类；
      selectedBrand: undefined, //选中品牌；
      productName: "", //产品名称输入框筛选值；
      productList: [],

      public: "assets/productCenter/menu",
      hoverIndex: "",

      currentSwiper: null,
      topImage: "",

      productInfo: [],
      productTypeList: [],
      productTypeOptions: [],
      productSubTypeList: [], //二级分类列表
      brandList: [], //品牌列表；

      startProductInfo: [], //头部明星产品信息
      starImages: [],
    };
  },
  computed: {
    selectedTypes(){
      const types = [];
      if (this.productType) {
        types.push(this.productType);
        if (this.selectedSubType) {
          types.push(this.selectedSubType);
        }
      }
      console.warn("computed selected types... ", types)
      return types;
    },
  },
  methods: {
    handleChange(v) {
      console.log(v);
    },

    searchProducts() {
      console.log("查询产品列表......")
      const condition =
      {
        page: 0,
        size: 100,
      };
      if (this.productName) {
        condition.name = this.productName;
      }
      if (this.selectedBrand) {
        condition.productBrandId = this.selectedBrand.id;
      }
      if (this.selectedSubType) {
        condition.category = this.selectedSubType.path;
      } else if (this.productType) {
        condition.category = this.productType.path;
      }

      request({
        url: "/api/ui/product/list",
        method: "get",
        params: condition,
      })
        .then((resp) => {
          console.info("收到查询产品列表结果！ -- ", resp);
          if (resp.success) {
            this.productList = resp.data.content;
          }
          else {
            console.error("查询产品列表时发生错误！ --" + resp.message, "   response: ", resp);
            return Promise.reject(resp.message)
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    handleMouseOver(index) {
      this.hoverIndex = index + 1;
    },
    handleMouseOut() {
      this.hoverIndex = "";
    },
    //初始化swiper
    initSwiper() {
      // const perView=this.screenWidth>850&&4
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      this.currentSwiper = new Swiper("#product-types-swipper", {
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        slidesPerView: 'auto', //视口展示
        slidesPerGroup: 1,
        slidesPerGroupAuto: true,
        // spaceBetween: 50,
      });
      this.currentSwiper2 = new Swiper("#start-products-swipper", {
        autoplay: true,
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        slidesPerView: 1, //视口展示slide数1
        pagination: {
          el: '.swiper-container .pagination',
          clickable: true,
        },

        effect: 'slide',
      });
    },
    //销毁swiper
    destroySwiper() {
      try {
        this.currentSwiper.destroy(true, false);
        this.currentSwiper2.destroy(true, true);
      } catch (e) {
        console.log("删除轮播");
      }
    },
    //更新swiper
    updateSwiper() {
      this.destroySwiper();
      this.$nextTick(() => {
        this.initSwiper();
      });
    },

    // 获取一级分类
    getProductType(defaultSelectedType) {
      const THIS = this;
      request({
        url: "/api/ui/productType/list",
        method: "get",
      })
        .then((resp) => {
          console.info("收到产品一级分类列表！ -- ", resp);
          if (resp.success) {
            THIS.productTypeOptions = [];
            THIS.productTypeList = resp.data;
            if (defaultSelectedType) {
              THIS.productTypeList.forEach(t => {
                if (t.path == defaultSelectedType) {
                  THIS.productType = t;
                }
              })
            }
            THIS.productTypeList.forEach(d => {
              var option = {
                value: d,
                label: d.name,
                children: [],
              }
              if (d.productTypes) {
                d.productTypes.forEach(p => {
                  var child = {
                    value: p,
                    label: p.name,
                  }
                  option.children.push(child);
                });
              }
              THIS.productTypeOptions.push(option);
            })
            this.searchProducts();
          }
          else {
            console.error("查询产品一级分类列表时发生错误！ --" + resp.message, "   response: ", resp);
            return Promise.reject(resp.message)
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },


    // 获取banner第一个明星产品信息
    getStarProductList() {
      request({
        url: "/api/ui/startproduct/list",
        method: "get",
      }).then((res) => {
        if (res.code == 0) {
          this.startProductInfo = res.data;
          this.starImages = [];
          res.data.forEach(element => {
            this.starImages.push(element.starImage.id);
          });
        }
      });
    },

    // 获取品牌
    getProductBrandList() {
      request({
        url: "/api/ui/productBrand/list",
        method: "get",
      })
        .then((resp) => {
          console.info("收到品牌列表！ -- ", resp);
          if (resp.success) {
            this.brandList = resp.data;
          }
          else {
            console.error("查询品牌列表时发生错误！ --" + resp.message, "   response: ", resp);
            return Promise.reject(resp.message)
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 点击产品跳转详情页
    handleProductClick(info) {
      if (info.imageTemplate) {
        let routeInfo = this.$router.resolve({
          path: "/product/detailImg",
          query: { productId: info.id },
        });
        window.open(routeInfo.href, "_blank");
      } else {
        let routeInfo = this.$router.resolve({
          path: "/product/detailImgAndText",
          query: { productId: info.id },
        });
        window.open(routeInfo.href, "_blank");
      }
    },

    switchMenuExpanding() {
      if (this.conditionMenuExpanded) {
        this.conditionMenuExpanded = undefined;
      } else {
        this.conditionMenuExpanded = "1";
      }
    },


    refreshProductSubTypeList() {
      if (this.productType == undefined) {
        return;
      }

      const typePath = this.productType.path;

      request({
        url: `/api/ui/productType/${typePath}`,
        method: "get",
      }).then((resp) => {
        console.info("收到产品类型[%s]的二级分类列表！ -- ", typePath, resp);
        if (resp.success) {
          this.productSubTypeList = resp.data;
        }
        else {
          console.error("查询产品类型[%s]的二级分类列表时发生错误！ --" + resp.message, "   response: ", resp);
          return Promise.reject(resp.message)
        }
      }).catch((e) => {
        console.log(e);
      });
    },

    // 选择了产品一级分类
    selectProductType(type) {
      const typePath = type ? type.path : undefined;
      // if (this.productType && this.productType.path == typePath) {
      //   // 自动反选；
      //   this.productType = undefined;
      // } else {
      //   this.productType = type;
      // }
      if (typePath) {
        this.productType = type;
      } else {
        this.productType = undefined;
      }
      // 更新了一级分类的选择后，清空二级分类的选择；
      //this.selectProductSubType(undefined);
      this.selectedSubType = undefined;
      this.productSubTypeList = undefined;

      // 触发搜索产品；
      this.searchProducts();

      // 刷新二级分类；
      this.refreshProductSubTypeList();

    },

    // 选择了产品二级分类；
    selectProductSubType(subType) {
      console.log("select product types ...", subType)
      var firstType = undefined;
      var secondType = undefined;
      if (subType) {
        firstType = subType[0];
        secondType = subType[1];
      }
      // 收起条件栏；
      this.conditionMenuExpanded = undefined;

      // 记录选择项；

      const typePath = firstType ? firstType.path : undefined;
      if (typePath) {
        // 自动反选；
        this.productType = firstType;
      } else {
        this.productType = undefined;
      }


      //const typePath = subType ? subType.path : undefined;
      const subTypePath = secondType ? secondType.path : undefined;
      if (this.selectedSubType && this.selectedSubType.path == subTypePath) {
        // 两次点击同一项时，取消选择；
        this.selectedSubType = undefined;
        console.log("clear selection of the productSubType[%s]...", subTypePath);
      } else {
        // this.selectedSubType = subType;
        this.selectedSubType = secondType;
        console.log("select the productSubType[%s]...", subTypePath);
      }
      this.searchProducts();
    },

    /**
     * 逐级清除选择的类型；
     */
    clearSelectedType() {
      if (this.selectedSubType) {
        this.selectProductSubType(undefined);
      } else {
        this.selectProductType(undefined);
      }
       // 清空选中的节点
      this.$refs.cascaderPanel.clearCheckedNodes();
    },

    selectBrand(brand) {
      //收起条件栏；
      this.conditionMenuExpanded = undefined;

      const brandId = brand ? brand.id : undefined;
      if (this.selectedBrand && this.selectedBrand.id == brandId) {
        // 两次点击同一项时，取消选择；
        this.selectedBrand = undefined;
        console.log("clear select the brand[%s]...  ", brandId, brand);
      } else {
        this.selectedBrand = brand;
        console.log("select the brand[%s]...", brandId, brand);
      }

      this.searchProducts();
    },

  },


  mounted() {

    var brandParam = this.$route.params.brand;
    if (brandParam) {
      this.selectedBrand = JSON.parse(brandParam);
    }
    var defaultSelectedType = this.$route.query.type;
    if (defaultSelectedType) {
      defaultSelectedType = defaultSelectedType.trim();
    }
    console.log("loading products....   defaultSelectedType=", defaultSelectedType)
    this.getProductType(defaultSelectedType);
    this.getProductBrandList();
    this.getStarProductList();

    setTimeout(() => {
      this.initSwiper();
    }, 1000);
  },
};
</script>

<style lang="less">

.el-cascader-node__label{
  font-size: 16px;
}

.el-cascader-menu__wrap.el-scrollbar__wrap {
    height: 100%;
}

.el-cascader-node.is-active{
  color: rgba(0, 160, 233, 1);
  font-weight: 400;
}

.el-cascader-node.in-active-path{
  color: var(--el-button-text-color);
  font-weight: 400;
}

.el-cascader-node:hover{
  color: rgba(0, 160, 233, 1);
  // font-weight: 400;
}


</style>


<style lang="less" scoped>
.p-classify {
  padding: 50px 0;

  .p-classify-action {
    font-size: 20px;
    padding: 0 45px;
    cursor: pointer;
    visibility: hidden;
  }

  @media screen and (max-width: 500px) {
    .p-classify-action {
      padding: 0 20px;
    }
  }
}

.p-classify:hover {
  .p-classify-action {
    visibility: visible;
  }
}

.el-col {
  margin-bottom: 20px;
}

.about {
  height: inherit;
  width: 100%;
  overflow: hidden;
}

.banner {
  position: relative;
  height: 781px;

  // :hover{
  //   color: #00A0E9;
  // }
  .el-link {
    position: absolute;
    bottom: 16%;
    left: 13%;
    width: 160px;
    height: 48px;
    background: rgba(0, 0, 0, 0);
    border: 1px solid #ffffff;
    border-radius: 27px;
    font-size: 22px;
    /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
    font-weight: 500;
    color: #ffffff;
  }
}

@media screen and (max-width: 860px) {
  .banner {
    .el-button {
      bottom: 15%;
      left: 10%;
      width: 200px;
      height: 40px;
      border-radius: 20px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 500px) {

  .banner {
    .el-button {
      bottom: 10%;
      left: 5%;
      width: 120px;
      height: 30px;
      border-radius: 15px;
      font-size: 16px;
      font-weight: 400;
    }
    .el-link {
      bottom: 30%;
    }
  }
}

.el-button:hover {
  color: var(--el-button-hover-text-color);
  border-color: var(--el-button-hover-border-color);
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
}

.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_classification {
  width: unset;
  max-width: 100%;
}

.products-container {
  background-color: #FFFFFF;
  box-sizing: border-box;
}

.products-pannel {
  width: 100%;
  min-height: 560px;
  padding: 120px 90px;
  position: relative;

  box-sizing: border-box;


  @media screen and (max-width: 850px) {
    padding: 160px 90px 90px 100px;

  }
}

.product-type-bar {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 6px 0 0 0;
}

.product-type-divider {
  width: 2px;
  padding: 0px;
  //margin: 0 0 2px 0;
  margin: -40px 0 2px 0;
  height: 0.9em;


}

.product-type-bar-divider.el-divider--horizontal {
  margin: 0;
}


.product-type-box {
  width: 137px;
  color: rgba(88, 88, 88, 1);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;

}

// .product-type-box:hover {
//   color: rgba(0, 160, 233, 1);

//   .svg-image {
//     color: rgba(0, 160, 233, 1);
//     scale: (1.06);
//   }
// }

.product-type-name {
  font-size: 16px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  margin-top: 2px;
}



.selected-item {
  color: rgba(0, 160, 233, 1);
  scale: (1.06);
  // box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
}

.infoMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .product-type-logo {
//   height: 60px;

//   img {
//     height: 100%;
//     width: auto;
//   }
// }


.condition-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  z-index: 2000;

  padding: 0 0 20px 0;

  box-sizing: border-box;
}

.condition-menu-panel {
  padding: 0px;

}

.condition-menu-panel.condition-menu-expended {
  box-shadow: 0px 10px 60px -40px rgba(0, 0, 0, 0.2);
}

.condition-menu-collapse {
  width: 100%;
}

.condition-menu-docker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  padding: 0 90px;

  z-index: 4000;

  @media screen and (max-width: 850px) {
    height: auto;
    padding: 20px 90px;
  }
}

.condition-menu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  column-gap: 16px;

  @media screen and (max-width: 850px) {
    flex-direction: column-reverse;
    justify-content: center;
    row-gap: 16px;
  }
}

.condition-menu-placeholder {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.menu-arrow {
  height: 6px;
  width: 12px;
  color: #333336;
}

.condition-selector-bar {
  width: 100%;
  display: flex;

  @media screen and (max-width: 850px) {
    align-items: flex-start;
  }
}

.condition-selector-label {
  width: 50%;
  font-size: 19px;
  color: #000000;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;

  .el-link {
    font-size: 19px;
    /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
    color: #000000;

    .el-icon {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.condition-name {
  width: 100%;
  max-width: 280px;
  min-width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 850px) {
    max-width: 100%;
  }
}

.condition-name-input {
  width: 100%;
  color: #585858;
  height: 42px;
  font-size: 16px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
}


.condition-options-panel {
  width: 100%;
  height: 350px;

  display: flex;
  align-content: stretch;
  flex-wrap: nowrap;
  flex-direction: row;

  padding: 40px 0 0 0;
}

.condition-option {
  width: 160px;
}

.condition-option-text {
  font-size: 16px;
}

.condition-options-types {
  width: 49.5%;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  .types-pannel {
    height: 100%;
    width: 100%;
  }
}

.condition-options-bands {
  width: 50%;

  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  grid-template-rows: repeat(8, 40px);
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-column-gap: 100px;
}

.newsBox {
  width: 100%;
  box-sizing: border-box;
}

.detailBottom {
  margin: 20px 0;
}

.textCh {
  font-size: 16px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  line-height: 26px;
}

.textEn {
  font-size: 16px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  line-height: 26px;
}

.el-input__icon {
  cursor: pointer;
}

// .el-input{
//   background-color: transparent;
// }
// .el-input__wrapper{
//   background-color: transparent;
// }
// /deep/.el-input__inner{
//   background: none;
// }
// .el-input__suffix{
//   background-color: transparent;
// }


/** 覆盖 el-collapse 折叠面板的样式，实现产品查询条件菜单  */
/deep/ .el-collapse {
  padding: 0 90px 20px 90px;
  border: 0px;
}

/deep/ .el-collapse-item__header {
  background-color: transparent;
  height: 90px;
  border: 0px;
  box-sizing: border-box;
  pointer-events: none;

  //隐藏面板头部右侧的箭头；
  .el-collapse-item__arrow {
    width: 0px;
    padding: 0px;
    border-width: 0px;
    visibility: hidden;
    position: fixed;
  }
}

/deep/ .el-input__wrapper {
  background-color: #F8FBFC;
}

/deep/ .el-collapse-item__wrap {
  background: #FFFFFF;
  opacity: 0.9;
  border: 0px;
}

/** 覆盖 el-button 按钮成组排列时的样式，以实现品类、品牌条件选项的整齐排列 */

/deep/ .el-button {
  justify-content: flex-start;
}

/deep/.el-button+.el-button {
  margin-left: 0px;
}

.pagination {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  padding: 0 80px;
  bottom: 60px;

}

@media screen and (max-width: 850px) {
  .pagination {
    justify-content: center;
    padding: 0px;
  }
}

:deep(.pagination .swiper-pagination-bullet) {
  display: inline-block !important;
  width: 9px !important;
  height: 9px !important;
  margin-left: 18px !important;
  background: #ffffff !important;
  border-radius: 50% !important;
  border: 1px solid #ffffff;
  transition: width 0.2s !important;
  cursor: pointer !important;
}

/* 被选中的分页 */
:deep(.pagination .swiper-pagination-bullet-active) {
  width: 9px !important;
  height: 9px !important;
  background: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 4px !important;
}

.swiper-container {
  height: 100% !important;
}

.swiper-wrapper {
  height: 100% !important;
}

.swiper-slide {
  height: 100%;
}

.star-product-box {
  position: relative;
  padding: 0px;
  height: 100%;

}

.mask-container {
  position: relative;
  padding: 0px;
  height: 100%;
}

.slogan-container {
  width: 100%;
  height: 265px;
  position: absolute;
  bottom: 4px;

  padding: 0px 90px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
}

.slogan-text-panel {
  width: 100%;
  max-width: 1740px;
  height: 100%;

  box-sizing: border-box;

  position: relative;
}

.banner .el-link:hover {
  border-color: #00a0e9;
  color: #00a0e9;
}

.productImage {
  border-radius: 10px;
  display: block;
  position: relative;
}

.productImage:hover .imgMask {
  opacity: 1;
}

.imgMask {
  position: absolute;
  background: rgba(101, 101, 101, 0.6);
  color: #ffffff;
  opacity: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
}

.imgMask h3 {
  text-align: center;
  margin-top: 25%;
}

.el-cascader-panel {
  border: none;
}

// :deep(.el-cascader-panel .el-cascader-menu){
//   border-right: none;
// }
</style>

